import { defineComponent } from 'vue';
import useRouter from 'vue-router';

export default defineComponent({
  props: {
    onClick: {
      type: Function,
      required: false
    }
  },
  setup(props) {
    const router = new useRouter();
    const listRouteTo = {
      'team-requests': '/team-requests',
      Checkout: '/explorar'
    };

    const goBack = async () => {
      if (props.onClick) {
        props.onClick();
        return;
      }

      try {
        const pathSegments = window.location.pathname.split('/').filter(Boolean);
        if (pathSegments.length > 1) {
          const baseRoute = listRouteTo[pathSegments[0]];
          if (baseRoute) {
            const newPath = baseRoute;
            window.location.href = newPath;
          } else {
            router.back();
          }
        } else {
          router.back();
        }
      } catch (error) {
        router.back();
      }
    };

    return {
      goBack
    };
  }
});
