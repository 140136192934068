import posthog from 'posthog-js';

const noop = {
  init: () => {},
  identify: () => {},
  capture: () => {},
  reset: () => {}
  // Añadir otros métodos de PostHog según sea necesario
};

export default {
  install(Vue) {
    const apiKey = process.env.POSTHOG_API_KEY;
    if (process.env.NODE_ENV === 'production' && apiKey) {
      posthog.init(apiKey.trim(), {
        api_host: 'https://eu.i.posthog.com'
      });
      Vue.prototype.$posthog = posthog;
    } else {
      Vue.prototype.$posthog = noop;
    }
  }
};

export const usePosthog = () => {
  if (process.env.NODE_ENV === 'production') {
    return posthog;
  } else {
    return noop;
  }
};
