import { Module } from 'vuex';
import { teamRequests, TeamRequestsStateStorable } from './client/teamRequestsPageStore';

export interface ClientStateStorable {
  teamRequests: TeamRequestsStateStorable;
}

export const client: Module<ClientStateStorable, any> = {
  namespaced: true,
  modules: {
    teamRequests
  }
};
