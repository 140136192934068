import { Module } from 'vuex';
import { ManagerStateStorable, manager } from './managerPagesStore';
import { ClientStateStorable, client } from './clientPagesStore';

export interface PagesStateStorable {
  manager: ManagerStateStorable;
  client: ClientStateStorable;
}

export const pagesStore: Module<PagesStateStorable, any> = {
  namespaced: true,
  modules: {
    manager,
    client
  }
};
