import { inject, onMounted, ref } from 'vue';
import SModalCentered from '@/components/stemdo-components/s-modal-centered/SModalCentered.vue';
import { useI18N } from '@/plugins/i18n';
import SButton from '../s-button/SButton.vue';
import PdfService from '@/sections/admin/entities/pdf/pdf.service';

export default {
  props: {
    type: String as () => 'teamRequest' | 'stemdoer',
    required: true,
    id: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 650
    },
    onLinkCopied: {
      type: Function
    }
  },
  components: {
    SModalCentered,
    SButton
  },
  setup(props) {
    const i18n = useI18N();
    const isModalOpen = ref(false);
    const link = ref('');
    const baseUrl = window.location.origin;
    const hide = ref(false);
    const openPopup = ref(false);
    const pdfService: PdfService = inject('pdfService');

    onMounted(() => {
      if (window.location.pathname.includes('team-requests') || window.location.pathname.includes('stemdoer')) {
        hide.value = false;
      }
    });

    const getTitle = () => {
      return props.type === 'teamRequest'
        ? i18n.t('s-external-link.title-tr').toString()
        : i18n.t('s-external-link.title-stemdoer').toString();
    };
    const openModal = () => {
      if (props.type === 'teamRequest') {
        link.value = baseUrl + `/team-requests/${props.id}`;
      } else {
        link.value = baseUrl + `/stemdoer/${props.id}`;
      }
      isModalOpen.value = true;
    };
    const copyLink = (isHide: boolean) => {
      window.focus();
      const linkToCopy = isHide ? `${link.value.toString()}?h` : link.value.toString();
      navigator.clipboard.writeText(linkToCopy);
      isModalOpen.value = false;
      props.onLinkCopied(true, i18n.t('s-external-link.copy-link').toString());
    };

    const downloadPdf = (isAnonymous?: boolean) => {
      if (props.type === 'stemdoer') {
        pdfService.downloadStemdoerPdf(props.id).then(blobPdf => {
          pdfService.handlePdfDownload(`stemdoer-${props.id}.pdf`, blobPdf);
          isModalOpen.value = false;
        });
      } else if (props.type === 'teamRequest') {
        pdfService.downloadTeamRequestPdfFromTeamRequest(props.id, isAnonymous).then(blobPdf => {
          pdfService.handlePdfDownload(`team-request-${props.id}.pdf`, blobPdf);
          isModalOpen.value = false;
        });
      }
    };

    return {
      isModalOpen,
      link,
      hide,
      openPopup,
      openModal,
      getTitle,
      copyLink,
      downloadPdf
    };
  }
};
