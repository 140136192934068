var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "m-0 px-8 pt-6",
      attrs: { id: "requests-wrapper", fluid: "" },
    },
    [
      _c(
        "v-col",
        {
          staticClass: "pa-0 ma-0 d-flex align-center",
          attrs: { cols: "2", fluid: "" },
        },
        [
          _c("h3", {
            staticClass: "text-h3",
            staticStyle: { color: "var(--v-text-base)" },
            attrs: { id: "title-page-1" },
            domProps: {
              textContent: _vm._s(_vm.$t("requests-filter-list.title")),
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("TeamRequestsFilter", {
            attrs: {
              teamRequests: _vm.teamRequests,
              onCompanyChange: _vm.handleCompanyChange,
              onStatusChange: _vm.handleStatusChange,
              onSearch: _vm.handleSearchChange,
              displaySkeleton: _vm.displaySkeleton,
            },
          }),
          _vm._v(" "),
          _c("TeamRequestsList", {
            attrs: {
              teamRequests: _vm.filteredTeamRequests,
              displaySkeleton: _vm.displaySkeleton,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }