import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    headers: {
      type: Array,
      required: true
    }
  },
  components: {},

  setup(props) {
    const headers = computed(() => props.headers.map(header => ({ text: header, value: 'skeleton' })));

    return {
      headers
    };
  }
});
