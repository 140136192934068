var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isLoadedTheNotificationList
    ? _c(
        "div",
        {
          staticClass: "d-flex justify-content-end align-items-end",
          attrs: { id: "notification-component" },
        },
        [
          _c(
            "v-list-group",
            {
              attrs: { id: "notification-dropmenu" },
              on: { click: _vm.handleMarkAllAsRead },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function () {
                      return [
                        _c(
                          "v-list-item",
                          { attrs: { dense: "", nav: "" } },
                          [
                            _c(
                              "v-list-item-title",
                              {
                                staticClass:
                                  "text--text text--base text-body-1-bold d-flex align-items-center ml-0",
                              },
                              [
                                _vm.checkNotificationUnRead()
                                  ? _c("img", {
                                      attrs: {
                                        src: "content/svgs/notification/bell-with-noti.svg",
                                        alt: "notification",
                                      },
                                    })
                                  : _c("img", {
                                      staticClass: "mr-3",
                                      attrs: {
                                        src: "content/svgs/notification/bell.svg",
                                        alt: "notification",
                                      },
                                    }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.notificationList.length ?? 0)
                                  ),
                                ]),
                                _vm._v(" \n          "),
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("sNotification.base-text")
                                    ),
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1805668759
              ),
            },
            [
              _vm._v(" "),
              _vm._l(_vm.notificationList, function (noti, i) {
                return _c(
                  "v-list-item",
                  { key: i, staticStyle: { margin: "0px 15px" } },
                  [
                    _c("v-list-item-title", {
                      domProps: { textContent: _vm._s(noti.teamRequestName) },
                    }),
                    _vm._v(" "),
                    _c("v-list-item-icon", [
                      _c("div", { staticClass: "btn-noti" }, [
                        _c("img", {
                          attrs: {
                            src: "content/svgs/notification/arrow-back.svg",
                            alt: "notification",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.routeToNotification(noti)
                            },
                          },
                        }),
                      ]),
                    ]),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }