import { Module } from 'vuex';

const key = 'clientTeamRequestsPageFilter';

export interface TeamRequestsStateStorable {
  filters: { status: string[] };
}

export const defaultTeamRequestsState: TeamRequestsStateStorable = {
  filters: {
    status: undefined
  }
};

export const teamRequests: Module<TeamRequestsStateStorable, any> = {
  namespaced: true,
  state: {
    filters: localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : defaultTeamRequestsState.filters
  },
  getters: {
    filters: state => getFiltersFromState(state)
  },
  mutations: {
    setStatusFilter(state, status: string[]) {
      const filters = getFiltersFromState(state);
      filters.status = status;
      persistFiltersState(state, filters);
    }
  }
};

const getFiltersFromState = (state): any => {
  return Object.assign({}, state.filters);
};

const persistFiltersState = (state, filters: any) => {
  state.filters = filters;
  localStorage.setItem(key, JSON.stringify(filters));
};
