import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import { useStore } from '@/plugins/vuex';
import NoContent from '@/sections/client/team-requests/no-content/NoContent.vue';
import TeamRequestList from '@/sections/client/team-requests/team-request-list/TeamRequestList.vue';
import { StatusFilter } from './filter-cards/filterCards';
import FilterCards from './filter-cards/FilterCards.vue';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import sButton from '@/components/stemdo-components/s-button/SButton.vue';
import sModalHalfRight from '@/components/stemdo-components/s-modal-right/SModalRight.vue';
import { ITeamRequest } from '@/shared/model/team-request.model';
import router from '@/router';
import TeamRequestListSkeleton from '../../shared/team-request-list-skeleton/TeamRequestListSkeleton.vue';
import SPopUp from '@/components/stemdo-components/s-pop-up/SPopUp.vue';
import { useI18N } from '@/plugins/i18n';
import SNotification from '@/components/stemdo-components/s-notification/SNotification.vue';
import Filters from '@/shared/model/filter/filters.model';
import SelectionMultipleFilter from '@/shared/model/filter/single/selection-multiple-filter.model';

export default defineComponent({
  props: {
    openSuccessPopUp: {
      type: Boolean,
      required: false
    }
  },
  components: {
    NoContent,
    TeamRequestList,
    TeamRequestListSkeleton,
    FilterCards,
    sModalHalfRight,
    sButton,
    SPopUp,
    SNotification
  },
  setup(props) {
    const i18n = useI18N();
    const store = useStore();
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const teamRequests = ref<ITeamRequest[]>([]);
    const teamRequestsFiltered = ref<ITeamRequest[]>([]);
    const isLoading = ref(true);
    const isRequestDetailModalOpen = ref(false);
    const requestSelected = ref([]);
    const isCancelModalOpen = ref(false);
    const cancelModalTitleName = ref('');
    const requestToCancel = ref(null);
    const statusFilterSelected = ref<StatusFilter>(StatusFilter.All);
    const proposalLink = ref('');
    const isSuccessPopUpOpen = ref(false);
    const successPopUpText = ref('');

    const filters = ref<Filters>(new Filters('AND'));

    const displaySkeleton = computed(() => {
      return isLoading.value || (teamRequests.value.length === 0 && isLoading.value);
    });

    onMounted(() => {
      retrieveAllClientTeamRequestByClientId();
      if (props.openSuccessPopUp) {
        successPopUpText.value = i18n.t('requests.savedSuccessPopupText').toString();
        isSuccessPopUpOpen.value = true;
      }
    });

    watch(
      () => filters.value,
      f => {
        teamRequestsFiltered.value = f.filter(teamRequests.value);
      },
      { deep: true }
    );

    const closeSuccessPopUp = () => {
      isSuccessPopUpOpen.value = false;
    };

    const closeCancelModal = () => {
      isCancelModalOpen.value = false;
    };

    const openCancelModal = (item: any) => {
      requestToCancel.value = item;
      cancelModalTitleName.value = item.name;
      isCancelModalOpen.value = true;
    };

    const cancelTeamRequest = () => {
      teamRequestService
        .changeStatus(requestToCancel.value.id, RequestStatus.CANCELLED_BY_CLIENT)
        .then(res => {
          retrieveAllClientTeamRequestByClientId();
        })
        .catch(err => {
          console.error(err);
        });
      isCancelModalOpen.value = false;
    };

    const openRequestDetailModal = request => {
      requestSelected.value = request;
      isRequestDetailModalOpen.value = true;
      checkStatusAndGenerateLink(request);
    };

    const closeRequestDetailModal = () => {
      isRequestDetailModalOpen.value = false;
      proposalLink.value = '';
    };

    const handleStatusFilterSelection = filterName => {
      const statusIds = [];
      statusFilterSelected.value = filterName;
      isLoading.value = true;
      if (filterName === StatusFilter.Saved) statusIds.push(RequestStatus.SAVED);
      if (filterName === StatusFilter.OnGoing) statusIds.push(RequestStatus.PENDING, RequestStatus.VALIDATED);
      if (filterName === StatusFilter.Pending) statusIds.push(RequestStatus.PENDING);
      if (filterName === StatusFilter.Validated) statusIds.push(RequestStatus.VALIDATED);
      if (filterName === StatusFilter.Approved) statusIds.push(RequestStatus.ON_GOING);
      if (filterName === StatusFilter.Rejected) statusIds.push(RequestStatus.CANCELLED_BY_MANAGER);

      filters.value.add(new SelectionMultipleFilter('status', statusIds));
      store.commit('pagesStore/client/teamRequests/setStatusFilter', statusIds);
      isLoading.value = false;
    };

    const refreshTeamRequestList = () => {
      retrieveAllClientTeamRequestByClientId();
    };

    const retrieveAllClientTeamRequestByClientId = () => {
      const clientId = store.getters.clientId;
      const getAllTeamRequestsByClientIdBody = {
        requestStatus: [
          RequestStatus.SAVED,
          RequestStatus.PENDING,
          RequestStatus.VALIDATED,
          RequestStatus.ON_GOING,
          RequestStatus.CANCELLED_BY_MANAGER
        ]
      };
      isLoading.value = true;
      if (clientId != null) {
        teamRequestService
          .getAllTeamRequestsByClientId(clientId, getAllTeamRequestsByClientIdBody)
          .then(res => {
            teamRequests.value = res.data;
            teamRequestsFiltered.value = res.data;
            isLoading.value = false;
          })
          .catch(err => {
            console.error(err);
          });
      }
    };

    const checkStatusAndGenerateLink = requestSelected => {
      if (requestSelected.status === RequestStatus.SAVED) {
        const link = router.resolve({ name: 'teamRequestByClientId', params: { id: requestSelected.id } }).href;
        proposalLink.value = window.location.origin + link;
        return proposalLink.value;
      }
      return null;
    };

    const copyToClipboard = () => {
      navigator.clipboard.writeText(proposalLink.value);
    };

    return {
      teamRequests,
      teamRequestsFiltered,
      cancelModalTitleName,
      isLoading,
      isRequestDetailModalOpen,
      requestSelected,
      isCancelModalOpen,
      statusFilterSelected,
      proposalLink,
      displaySkeleton,
      isSuccessPopUpOpen,
      successPopUpText,
      closeSuccessPopUp,
      handleStatusFilterSelection,
      openRequestDetailModal,
      closeRequestDetailModal,
      cancelTeamRequest,
      openCancelModal,
      closeCancelModal,
      refreshTeamRequestList,
      copyToClipboard
    };
  }
});
