var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "mx-0 mb-8 pl-0 pt-0",
      attrs: { id: "request-list", fluid: "" },
    },
    [
      _vm.displaySkeleton
        ? _c("SSkeletonTable", { attrs: { headers: _vm.headers } })
        : _c("v-data-table", {
            staticClass: "remove-box-shadow manager-request-table",
            attrs: {
              headers: _vm.headers,
              items: _vm.teamRequestsInPage,
              "disable-sort": "",
              "hide-default-footer": "",
            },
            on: { "click:row": _vm.handleClickDetail },
            scopedSlots: _vm._u([
              {
                key: "item.stemdoers",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "chip-container" },
                      [
                        _vm._l(
                          item.stemdoerRates,
                          function (stemdoerRate, index) {
                            return [
                              index < 3
                                ? _c(
                                    "v-chip",
                                    {
                                      key: "chip-rate-" + index,
                                      staticClass:
                                        "overlapping-chip no-padding",
                                      attrs: { color: "transparent" },
                                    },
                                    [
                                      _c("SAvatarStemdoer", {
                                        staticClass: "avatar",
                                        attrs: {
                                          stemdoId: stemdoerRate.stemdoId,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : index === 3
                                ? _c(
                                    "v-chip",
                                    {
                                      key: "chip-rate-" + index,
                                      staticClass:
                                        "overlapping-chip no-padding",
                                      staticStyle: {
                                        border: "1px solid white !important",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            +" +
                                          _vm._s(
                                            item.stemdoerRates.length - 3
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                },
              },
              {
                key: "item.client",
                fn: function ({ item }) {
                  return [
                    _c("div", { staticClass: "client-info" }, [
                      _c("span", { staticClass: "text-body-1" }, [
                        _vm._v(
                          _vm._s(item.client?.name) +
                            " " +
                            _vm._s(item.client?.surname)
                        ),
                      ]),
                      _vm._v(" "),
                      item.client &&
                      item.client.company &&
                      item.client.company.name
                        ? _c("span", { staticClass: "text-body-1-medium" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.client.company.name) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
              {
                key: "item.initDate",
                fn: function ({ item }) {
                  return [
                    item.initDate
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$d(Date.parse(item.initDate), "twoDigit")
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.logSummary.lastView",
                fn: function ({ item }) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          item.logSummary.lastView
                            ? _vm.$d(
                                Date.parse(item.logSummary.lastView),
                                "twoDigit"
                              )
                            : ""
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "item.logSummary.numberOfViews",
                fn: function ({ item }) {
                  return [
                    _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(item.logSummary.numberOfViews) +
                          "\n        " +
                          _vm._s(
                            item.logSummary.numberOfViews === 1
                              ? _vm.$t("requests-filter-list.list.time")
                              : _vm.$t("requests-filter-list.list.times")
                          ) +
                          "\n      "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "status-container" },
                      [
                        _c("SLabel", {
                          staticClass: "text-body-3 p-1 pl-3 pr-3",
                          attrs: {
                            text: _vm.getStatusDetails(item.status).translation,
                            labelStyle: _vm.getStatusDetails(item.status)
                              .labelStyle,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item.action",
                fn: function ({ item }) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "teamRequestByIdManager",
                            params: { id: item.id },
                          },
                        },
                      },
                      [
                        _c(
                          "v-btn",
                          { staticClass: "v-icon-detail", attrs: { icon: "" } },
                          [
                            _c("img", {
                              attrs: {
                                src: "content/svgs/eye-outline.svg",
                                alt: "detail",
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
      _vm._v(" "),
      !_vm.displaySkeleton
        ? _c(
            "div",
            { staticClass: "pagination-container" },
            [
              _c("SPagination", {
                attrs: {
                  itemsPerPage: _vm.ITEMS_PER_PAGE,
                  totalItems: _vm.teamRequests.length,
                  onPageChange: _vm.onPageChange,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }