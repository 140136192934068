var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-autocomplete", {
    staticClass: "custom-v-autocomplete",
    style: _vm.customStyle,
    attrs: {
      label: _vm.$t(_vm.placeholder),
      items: _vm.items,
      "item-value": "id",
      "item-text": "desc",
      height: _vm.inputHeight,
      "return-object": "",
      disabled: _vm.disabled,
      multiple: "",
      dense: "",
      flat: "",
      solo: "",
    },
    on: {
      change: _vm.handleChange,
      blur: _vm.handleBlur,
      focus: _vm.handleFocus,
    },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function ({ index }) {
          return [
            !_vm.labelDisplaySelection && index === 0
              ? _c(
                  "span",
                  {
                    staticClass:
                      "pt-1 pb-1 text-body-1 text--text text--lighten-1",
                  },
                  [_vm._v("\n      " + _vm._s(_vm.labelDisplay) + "\n    ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.labelDisplaySelection && index === 0
              ? _c(
                  "span",
                  {
                    staticClass:
                      "pt-1 pb-1 text-body-1-medium text--text text--base",
                  },
                  [_vm._v("\n      " + _vm._s(_vm.labelDisplay) + "\n    ")]
                )
              : _vm._e(),
          ]
        },
      },
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c(
              "div",
              {
                class: [
                  "d-inline-flex",
                  "align-center",
                  "w-100",
                  "h-100",
                  {
                    "selected-item":
                      _vm.isItemSelected(item) || _vm.showRating(item),
                  },
                  { "font-weight-bold": _vm.isItemSelected(item) },
                ],
                staticStyle: {
                  "min-width": "100% !important",
                  "min-height": "100% !important",
                },
                on: {
                  click: function ($event) {
                    if ($event.target !== $event.currentTarget) return null
                    return _vm.handleItemSelection(item)
                  },
                  mouseover: function ($event) {
                    return _vm.handleMouseOver(item.id)
                  },
                  mouseleave: _vm.handleMouseLeave,
                },
              },
              [
                _c(
                  "div",
                  {
                    class: [
                      "item-desc",
                      "mr-2",
                      "text--text ",
                      "text--base",
                      { "selected-item": _vm.isItemSelected(item) },
                      { "text-body-1": !_vm.isItemSelected(item) },
                      { "text-body-1-bold": _vm.isItemSelected(item) },
                    ],
                    on: {
                      click: function ($event) {
                        if ($event.target !== $event.currentTarget) return null
                        return _vm.handleItemSelection(item)
                      },
                    },
                  },
                  [_vm._v("\n        " + _vm._s(item.desc) + "\n      ")]
                ),
                _vm._v(" "),
                _vm.showRating(item)
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "text-center d-inline-flex align-center h-100",
                      },
                      [
                        _c("v-rating", {
                          staticClass: "d-inline-flex align-center h-100",
                          attrs: { length: "3", clearable: "", dense: "" },
                          on: {
                            input: function ($event) {
                              if ($event.target !== $event.currentTarget)
                                return null
                              return _vm.handleRatingChange(item)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          top: "",
                                          "content-class":
                                            "background-darken3 tooltip-top",
                                          "nudge-right": "0",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-img",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            width: 20,
                                                            src:
                                                              "/content/svgs/star/" +
                                                              (props.isFilled
                                                                ? "star-fill-blue"
                                                                : _vm.isStarHovered(
                                                                    item.id,
                                                                    props.index
                                                                  )
                                                                ? "star-empty-blue"
                                                                : "star-empty-grey-lighten1") +
                                                              ".svg",
                                                          },
                                                          on: {
                                                            click: props.click,
                                                            mouseover:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.handleStarHover(
                                                                  item.id,
                                                                  props.index
                                                                )
                                                              },
                                                            mouseleave:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.handleStarLeave(
                                                                  item.id
                                                                )
                                                              },
                                                          },
                                                        },
                                                        "v-img",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pt-1" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "pr-1 text-body-4-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getTitleBadge(
                                                        _vm.currentTier
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.currentTier,
                                                function (star) {
                                                  return _c(
                                                    "span",
                                                    { key: star },
                                                    [
                                                      _c("v-img", {
                                                        attrs: {
                                                          width: 20,
                                                          src: "/content/svgs/star/star-fill-white.svg",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            { staticClass: "mb-2 text-body-3" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getInfoBadge(
                                                    _vm.currentTier,
                                                    item.id
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: item.rating,
                            callback: function ($$v) {
                              _vm.$set(item, "rating", $$v)
                            },
                            expression: "item.rating",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectedItemComponentModel,
      callback: function ($$v) {
        _vm.selectedItemComponentModel = $$v
      },
      expression: "selectedItemComponentModel",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }