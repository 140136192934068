import { useStore } from '@/plugins/vuex';
import localStorageFacade from '../local-store-facade/LocalStoreFacade';
import { usePosthog } from '@/plugins/posthog';

export const logout = () => {
  const posthog = usePosthog();
  const store = useStore();
  store.commit('logout');
  store.commit('cartStore/clearCart');
  store.commit('filterStore/clearToInitState');
  localStorageFacade.clearSession();
  posthog.reset();
};
