var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.hide
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-end align-items-end" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btnPDF",
                  attrs: { fab: "" },
                  on: {
                    click: function ($event) {
                      return _vm.openModal()
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "iconPDF",
                    attrs: {
                      src: "/content/svgs/header/external-link.svg",
                      width: "24px",
                      height: "24px",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("SModalCentered", {
            attrs: {
              maxWidth: _vm.width.toString(),
              title: _vm.getTitle(),
              isOpen: _vm.isModalOpen,
              noFooter: true,
              closeModal: () => {
                _vm.isModalOpen = false
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("div", {
                        staticClass:
                          "text-subtitle-3 text--text text--base mt-n2 mb-3",
                        domProps: {
                          textContent: _vm._s(_vm.$t("s-external-link.body")),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex no-gutters nowrap align-items-center",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex-grow-1 mt-1" },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "mb-0 pb-0 px-1 title-text-link",
                                },
                                [_vm._v("Datos visibles")]
                              ),
                              _vm._v(" "),
                              _c("v-text-field", {
                                staticClass: "text-field-link mt-2",
                                attrs: {
                                  label: _vm.link,
                                  outlined: "",
                                  dense: "",
                                  disabled: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "mx-2 mt-7" },
                            [
                              _c("SButton", {
                                staticClass: "btn-copiar",
                                attrs: {
                                  sText: "Copiar",
                                  action: () => _vm.copyLink(false),
                                  btnStyle: "secondary",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "mt-7" },
                            [
                              _c("SButton", {
                                staticClass: "btn-pdf",
                                attrs: {
                                  sText: "PDF",
                                  btnStyle: "secondary",
                                  action: () => {
                                    _vm.downloadPdf(false)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex no-gutters nowrap align-items-center mt-2",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex-grow-1" },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "mb-0 pb-0 px-1 title-text-link",
                                },
                                [_vm._v("Datos ocultos")]
                              ),
                              _vm._v(" "),
                              _c("v-text-field", {
                                staticClass: "text-field-link mt-2",
                                attrs: {
                                  label: _vm.link + "?h",
                                  outlined: "",
                                  dense: "",
                                  disabled: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "mx-2 mt-6" },
                            [
                              _c("SButton", {
                                staticClass: "btn-copiar",
                                attrs: {
                                  sText: "Copiar",
                                  action: () => _vm.copyLink(true),
                                  btnStyle: "secondary",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "mt-6" },
                            [
                              _c("SButton", {
                                staticClass: "btn-pdf",
                                attrs: {
                                  sText: "PDF",
                                  btnStyle: "secondary",
                                  action: () => {
                                    _vm.downloadPdf(true)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex no-gutters nowrap align-items-center my-3",
                        },
                        [
                          _vm.type != "teamRequest"
                            ? _c(
                                "a",
                                {
                                  staticClass: "link-abrir-perfil",
                                  attrs: { href: _vm.link, target: "_blank" },
                                },
                                [_vm._v("Abrir perfil en una nueva pestaña")]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              482953975
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }