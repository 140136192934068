import { Component, Inject, Vue } from 'vue-property-decorator';
import AccountService from '@/sections/account/account.service';
import TranslationService from '@/locale/translation.service';
import EntitiesMenu from '@/sections/admin/entities/entities-menu.vue';
import { BBreadcrumb } from 'bootstrap-vue';
import { EMAIL_ALREADY_USED_TYPE } from '@/constants';
import axios from 'axios';
import { Authority } from '@/shared/security/authority';
import localStorageFacade, { StorageKey } from '@/shared/local-store-facade/LocalStoreFacade';
import AuthToken from '@/shared/security/AuthToken';
import { logout } from '@/shared/security/security';
Vue.component('b-breadcrumb', BBreadcrumb);

@Component({
  data() {
    return {
      Authority: Authority
    };
  },
  components: {
    'entities-menu': EntitiesMenu
  },
  watch: {
    $route(to) {
      this.routeName = to.path.replace('/', '') == 'not-found' ? to.path.replaceAll('/', '') : to.path.replaceAll('/', '.');
      this.routePath = to.path.split('/').slice(1, to.path.split('/').length);
      this.url = to.query['idProject'];
      to.path.replace('/', '') == 'not-found' ? false : this.breadCrumGenerator();
    }
  }
})
export default class JhiNavbar extends Vue {
  @Inject('translationService') private translationService: () => TranslationService;
  @Inject('accountService') private accountService: () => AccountService;

  public version = 'v' + VERSION;
  private currentLanguage = this.$store.getters.currentLanguage;
  private languages: any = this.$store.getters.languages;
  private hasAnyAuthorityValues = {};
  public routeName = '';
  public routePath = [];
  public breadCrumList = [];
  public success: string = null;
  public error: string = null;
  public errorEmailExists: string = null;
  public url = '';
  public singleUrlWord = window.location.pathname.replace('/', '');
  public reload = false;
  public notiArray = [];
  public isLoad = false;
  public notiLength = 0;
  public isHome = window.location.pathname == '/';
  public isAdminOrRRHH = false;

  public created() {
    /*const currentLanguage = Object.keys(this.languages).includes(navigator.language) ? navigator.language : this.currentLanguage;
    this.translationService().refreshTranslation(currentLanguage);*/
  }

  public mounted(): void {}

  public handleClick() {
    if (window.location.pathname.replace('/', '') == 'cookieInfo') {
      this.$router.go(-1);
    }
  }

  public getToLink() {
    if (window.location.pathname.replace('/', '') == 'cookieInfo') return undefined;
    return '/';
  }

  public subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some(path => {
      return this.$route.path.indexOf(path) === 0; // current path starts with this path string
    });
  }

  public changeLanguage(newLanguage: string): void {
    this.translationService().refreshTranslation(newLanguage);
  }

  public isActiveLanguage(key: string): boolean {
    return key === this.$store.getters.currentLanguage;
  }

  public logout(): Promise<any> {
    logout();
    if (this.$route.path !== '/') {
      // location.reload();
      return this.$router.push('/');
    }
    return Promise.resolve(this.$router.currentRoute);
  }

  public openLogin(): void {}

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public hasAnyAuthority(authorities: Authority[]): boolean {
    return AuthToken.hasAnyAuthority(authorities);
  }

  public get openAPIEnabled(): boolean {
    return this.$store.getters.activeProfiles.indexOf('api-docs') > -1;
  }

  public get inProduction(): boolean {
    return this.$store.getters.activeProfiles.indexOf('prod') > -1;
  }

  public getTitle(): string {
    switch (this.$route.path.split('/').length) {
      case 2:
        return 'titleViews.' + this.$route.path.split('/')[1] + '.' + 'name';
      case 3:
        return 'titleViews.' + this.$route.path.split('/')[1] + '.' + this.$route.path.split('/')[2];
      case 4:
        if (
          this.$route.path.split('/')[2] == 'selected' ||
          this.$route.path.split('/')[2] == 'view' ||
          this.$route.path.split('/')[2] == 'proposal'
        ) {
          return 'titleViews.' + this.$route.path.split('/')[1] + '.' + this.$route.path.split('/')[2];
        }
        return 'titleViews.' + this.$route.path.split('/')[1] + '.' + this.$route.path.split('/')[3];
      case 5:
        return 'titleViews.' + this.$route.path.split('/')[1] + '.' + this.$route.path.split('/')[4];
      default:
        return '';
    }
  }

  public breadCrumGenerator(): void {
    let list = [];
    this.breadCrumList = [
      {
        text: 'Home',
        to: '/'
      }
    ];
    switch (this.$route.path.split('/').length) {
      case 2:
        list = [
          {
            text: this.$t('breadcrumb.' + this.$route.path.split('/')[1] + '.' + 'name'),
            active: true
          }
        ];
        this.breadCrumList.push(...list);
        break;
      case 3:
        this.breadCrumList.push({
          text: this.$t('breadcrumb.' + this.$route.path.split('/')[1] + '.name'),
          to:
            this.$route.path.split('/')[1] == 'admin'
              ? this.$route.path.split('/')[1] + '/' + this.$route.path.split('/')[2]
              : this.$route.path.split('/')[1] == 'account'
              ? '/account/settings'
              : '/' + this.$route.path.split('/')[1]
        });
        this.breadCrumList.push({
          text: this.$t('breadcrumb.' + this.$route.path.split('/')[1] + '.' + this.$route.path.split('/')[2]),
          to: '/' + this.$route.path.split('/')[1]
        });
        break;
      case 4:
        this.breadCrumList.push({
          text: this.$t('breadcrumb.' + this.$route.path.split('/')[1] + '.name'),
          to: '/' + this.$route.path.split('/')[1]
        });
        if (
          this.$route.path.split('/')[2] == 'selected' ||
          this.$route.path.split('/')[2] == 'view' ||
          this.$route.path.split('/')[2] == 'proposal'
        ) {
          this.breadCrumList.push({
            text: this.$t('breadcrumb.' + this.$route.path.split('/')[1] + '.' + this.$route.path.split('/')[2]),
            to: '/' + this.$route.path.split('/')[1]
          });
        } else {
          this.breadCrumList.push({
            text: this.$t('breadcrumb.' + this.$route.path.split('/')[1] + '.' + this.$route.path.split('/')[3]),
            to: '/' + this.$route.path.split('/')[1]
          });
        }
        break;
      case 5:
        this.breadCrumList.push({
          text: this.$t('breadcrumb.' + this.$route.path.split('/')[1] + '.name'),
          to:
            this.$route.path.split('/')[1] == 'admin'
              ? this.$route.path.split('/')[1] + '/' + this.$route.path.split('/')[2]
              : '/' + this.$route.path.split('/')[1]
        });
        this.breadCrumList.push({
          text: this.$t('breadcrumb.' + this.$route.path.split('/')[1] + '.' + this.$route.path.split('/')[2]),
          to:
            this.$route.path.split('/')[1] == 'admin'
              ? this.$route.path.split('/')[1] + '/' + this.$route.path.split('/')[2]
              : '/' + this.$route.path.split('/')[1]
        });
        this.breadCrumList.push({
          text: this.$t('breadcrumb.' + this.$route.path.split('/')[1] + '.' + this.$route.path.split('/')[4]),
          to:
            this.$route.path.split('/')[1] == 'admin'
              ? this.$route.path.split('/')[1] + '/' + this.$route.path.split('/')[2]
              : '/' + this.$route.path.split('/')[1]
        });
        break;
      default:
        list = [
          {
            text: this.$t('breadcrumb.' + this.$route.path.split('/')[1]),
            to: '/' + this.routePath[0]
          }
        ];
        this.breadCrumList.push(...list);
        break;
    }
  }

  public get settingsAccount(): any {
    return this.$store.getters.account;
  }

  public save(idioma): void {
    this.error = null;
    this.errorEmailExists = null;
    this.settingsAccount.langKey = idioma;
    axios
      .post('api/account', this.settingsAccount)
      .then(res => {
        this.error = null;
        this.success = 'OK';
        this.errorEmailExists = null;
        location.reload();
      })
      .catch(error => {
        this.success = null;
        this.error = 'ERROR';
        if (error.response.status === 400 && error.response.data.type === EMAIL_ALREADY_USED_TYPE) {
          this.errorEmailExists = 'ERROR';
          this.error = null;
        }
      });
  }
}
