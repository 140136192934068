import { defineComponent, inject, onMounted, ref } from 'vue';
import { useStore } from '@/plugins/vuex';
import NotificationService from '@/sections/admin/entities/notification/notification.service';
import { INotification } from '@/shared/model/notification.model';
import useRouter from 'vue-router';

export default defineComponent({
  setup(props) {
    const store = useStore();
    const router = new useRouter();
    const notificationService: NotificationService = inject('notificationService');
    const isLoadedTheNotificationList = ref(false);
    const existUnReadNotification = ref(false);
    const notificationList = ref<INotification[]>([]);
    const isNotificationListOpened = ref(false);

    const checkNotificationUnRead = () => {
      const existUnRead = notificationList.value.some(notification => !notification.read);
      existUnReadNotification.value = existUnRead;
      return existUnReadNotification.value;
    };

    const handleMarkAllAsRead = () => {
      if (!isNotificationListOpened.value) {
        notificationList.value.forEach(notification => {
          notification.read = true;
        });
        checkNotificationUnRead();
        updateNotificationList();
      }
      isNotificationListOpened.value = true;
    };
    const updateNotificationList = () => {
      notificationService.updateList(notificationList.value).then(res => {});
    };

    const routeToNotification = notification => {
      notificationService
        .delete(notification.id)
        .then(res => {
          notificationList.value = notificationList.value.filter(noti => noti.id !== notification.id);
          window.location.href = '/team-requests/' + notification.teamRequestId;
        })
        .catch(err => {
          console.log('Error deleting notification: ', err);
        });
    };

    const retrieveAllNotificationByUserId = () => {
      const userId = store.getters.account.id;
      notificationService.findByUserId(userId).then(res => {
        notificationList.value = res;
      });
    };

    onMounted(() => {
      isLoadedTheNotificationList.value = true;
      retrieveAllNotificationByUserId();
      checkNotificationUnRead();
    });

    return {
      notificationList,
      isLoadedTheNotificationList,
      checkNotificationUnRead,
      handleMarkAllAsRead,
      routeToNotification
    };
  }
});
