import { render, staticRenderFns } from "./FilterCards.vue?vue&type=template&id=8878040a&scoped=true&class=d-flex%20flex-direction-column"
import script from "./filterCards.ts?vue&type=script&lang=ts&external"
export * from "./filterCards.ts?vue&type=script&lang=ts&external"
import style0 from "./FilterCards.vue?vue&type=style&index=0&id=8878040a&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8878040a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/r1/a/_stemdoplatform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8878040a')) {
      api.createRecord('8878040a', component.options)
    } else {
      api.reload('8878040a', component.options)
    }
    module.hot.accept("./FilterCards.vue?vue&type=template&id=8878040a&scoped=true&class=d-flex%20flex-direction-column", function () {
      api.rerender('8878040a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/sections/client/team-requests/filter-cards/FilterCards.vue"
export default component.exports