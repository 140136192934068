import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue';
import { useStore } from '@/plugins/vuex';
import { useI18N } from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import Filters, { FilterFields } from '@/sections/shared/explore/model/Filters';

export interface IAtocompleteMultipleRatingState {
  id: string | number;
  desc: string;
  rating: number;
}

export default defineComponent({
  props: {
    items: {
      type: Array as () => IAtocompleteMultipleRatingState[],
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    action: {
      type: Function,
      required: true
    },
    inputHeight: {
      type: Number,
      required: false
    },
    filterField: String,
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    labelDisplaySelection: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    tempFilters: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup(props) {
    const store = useStore();
    const i18n = useI18N();
    const selectedItemComponentModel = ref([]);
    const labelDisplay = ref('');
    const itemIdHover = ref(undefined);
    const selectedItems = ref([]);
    const currentTier = ref(1);
    const hoveredIndex = ref<{ [key: string]: number }>({});
    const customStyle = computed(() => {
      const themeColors: any = vuetify.framework.theme.currentTheme;
      return props.tempFilters === true ? { '--custom-color': themeColors.text.lighten2 } : { '--custom-color': themeColors.text.lighten1 };
    });

    onMounted(() => {
      if (props.filterField) {
        selectedItems.value = getComponentState();
        selectedItemComponentModel.value = getComponentState();
        updateRatingsInSelectedItems();
        setLabelDisplay();
      }
    });

    if (!props.tempFilters) {
      watch(
        () => store.getters['filterStore/filters'],
        (filters: Filters) => {
          selectedItems.value = getComponentState();
          updateRatingsInSelectedItems();
          if (selectedItems.value.length === 0) {
            selectedItemComponentModel.value = [];
          }
          setLabelDisplay();
        },
        { deep: true }
      );
    }

    if (props.tempFilters) {
      watch(
        () => store.getters['filterStore/tempFilters'],
        (filters: Filters) => {
          selectedItems.value = getComponentState();
          updateRatingsInSelectedItems();
          if (selectedItems.value.length === 0) {
            selectedItemComponentModel.value = [];
          }
          setLabelDisplay();
        },
        { deep: true }
      );
    }

    const getComponentState = () => {
      const payload = props.filterField as FilterFields;
      let state;
      if (!props.tempFilters) {
        state = store.getters['filterStore/getAutoCompleteMultipleRatingState'](payload);
      } else {
        state = store.getters['filterStore/getAutoCompleteMultipleRatingTempState'](payload);
      }
      return state;
    };

    const handleChange = items => {
      if (selectedItems.value.length === 0) {
        selectedItemComponentModel.value = [];
      }
    };

    const handleItemSelection = (itemSelected: IAtocompleteMultipleRatingState) => {
      const newItem = { id: itemSelected.id, desc: itemSelected.desc, rating: 1 };
      const findItem = selectedItems.value.find(selectedItem => selectedItem.id === itemSelected.id);
      if (findItem) {
        newItem.rating = 0;
      }
      props.action(props.filterField, newItem);
    };

    const handleRatingChange = (item: IAtocompleteMultipleRatingState) => {
      props.action(props.filterField, item);
    };

    const updateRatingsInSelectedItems = () => {
      addRatingToSelectedItemsWithoutIt();
      deleteRatingToNotSelectedItems();
    };

    const addRatingToSelectedItemsWithoutIt = () => {
      const selectedItemsIds = selectedItems.value.map(item => item.id);
      props.items.map(item => {
        if (selectedItemsIds.includes(item.id)) {
          const selectedItem = selectedItems.value.find(selectedItem => selectedItem.id === item.id);
          item.rating = selectedItem.rating;
        }
      });
    };

    const deleteRatingToNotSelectedItems = () => {
      const selectedItemsIds = selectedItems.value.map(item => item.id);
      props.items.map(item => {
        if (!selectedItemsIds.includes(item.id)) {
          item.rating = 0;
        }
      });
    };

    const isItemSelected = item => {
      const findItem = selectedItems.value.find(selectedItem => selectedItem.id === item.id);
      return findItem !== undefined;
    };

    const showRating = (item: IAtocompleteMultipleRatingState) => {
      if (item.id === itemIdHover.value) return true;
      if (item.rating && item.rating > 0) return true;
      return false;
    };

    const handleMouseOver = (itemId: string) => {
      itemIdHover.value = itemId;
    };

    const handleMouseLeave = () => {
      itemIdHover.value = undefined;
    };

    const handleBlur = () => {
      setLabelDisplay();
    };

    const setLabelDisplay = () => {
      if (!props.labelDisplaySelection) {
        labelDisplay.value = i18n.t(props.placeholder).toString();
        return;
      }

      if (selectedItems.value.length === 1) {
        labelDisplay.value = `${i18n.t(selectedItems.value[0].desc).toString()}`;
        return;
      }
      labelDisplay.value = `${i18n.t(props.label).toString()}·${selectedItems.value.length}`;
    };

    const handleFocus = () => {
      labelDisplay.value = '';
    };

    const getInfoBadge = (tier: number, id: number) => {
      let translationKey;
      translationKey = `technologyMaster.tech-badges.${id}.${tier}`;
      let translation = i18n.t(translationKey);
      if (translation === translationKey) {
        translation = i18n.t(translationKey.replace(id.toString(), '0'));
      }
      return translation;
    };

    const getTitleBadge = (tier: number) => {
      let translationKey;
      translationKey = `technologyMaster.tech-badges.tier.${tier}`;
      return i18n.t(translationKey);
    };

    const handleStarHover = (itemId: string | number, index: number) => {
      currentTier.value = index + 1;
      hoveredIndex.value = { ...hoveredIndex.value, [itemId]: index };
    };

    const handleStarLeave = (itemId: string | number) => {
      const { [itemId]: _, ...rest } = hoveredIndex.value;
      hoveredIndex.value = rest;
    };

    const isStarHovered = (itemId: string | number, index: number) => {
      return hoveredIndex.value[itemId] !== undefined && index <= hoveredIndex.value[itemId];
    };

    return {
      customStyle,
      labelDisplay,
      selectedItemComponentModel,
      handleChange,
      handleItemSelection,
      handleRatingChange,
      isItemSelected,
      handleMouseOver,
      handleMouseLeave,
      showRating,
      handleFocus,
      handleBlur,
      getInfoBadge,
      getTitleBadge,
      currentTier,
      handleStarHover,
      handleStarLeave,
      isStarHovered,
      hoveredIndex
    };
  }
});
