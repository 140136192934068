var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-data-table", {
    staticClass: "remove-box-shadow custom-table",
    attrs: {
      headers: _vm.headers,
      "hide-default-footer": "",
      "disable-pagination": "",
      "disable-sort": "",
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "tbody",
              [
                _vm._l(_vm.headers.length, function (item) {
                  return [
                    _c("tr", { key: `row-${item}` }, [
                      _c(
                        "td",
                        { attrs: { colspan: _vm.headers.length } },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "w-100",
                            attrs: { type: "text" },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                }),
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }