import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import { defineComponent, onMounted, computed, ref } from 'vue';
import { useI18N } from '@/plugins/i18n';
import { useStore } from '@/plugins/vuex';

export enum StatusFilter {
  All = 'ALL',
  Saved = 'SAVED',
  OnGoing = 'ON_GOING',
  Pending = 'PENDING',
  Validated = 'VALIDATED',
  Approved = 'APPROVED',
  Rejected = 'CANCELLED_BY_MANAGER'
}

export default defineComponent({
  props: {
    teamRequests: {
      type: Array,
      default: () => []
    },
    onClick: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const i18n = useI18N();
    const store = useStore();
    const selectedFilterCard = ref(StatusFilter.All);
    const selectedSubFilterCard = ref<StatusFilter[]>([]);
    const filterCardComponents = computed(() => [
      {
        val: StatusFilter.All,
        text: i18n.t('requests.status.all') as string,
        count: props.teamRequests.length,
        backgroundColor: 'var(--v-text-base)'
      },
      {
        val: StatusFilter.OnGoing,
        text: i18n.t('requests-filter-list.filter.status.onGoing') as string,
        count: props.teamRequests.filter(
          (teamRequest: any) => teamRequest.status === RequestStatus.PENDING || teamRequest.status === RequestStatus.VALIDATED
        ).length,
        backgroundColor: 'var(--v-quinary-base)'
      },
      {
        val: StatusFilter.Approved,
        text: i18n.t('requests-filter-list.filter.status.approved') as string,
        count: props.teamRequests.filter((teamRequest: any) => teamRequest.status === RequestStatus.ON_GOING).length,
        backgroundColor: 'var(--v-tertiary-base)'
      },
      {
        val: StatusFilter.Rejected,
        text: i18n.t('requests-filter-list.filter.status.rejected') as string,
        count: props.teamRequests.filter((teamRequest: any) => teamRequest.status === RequestStatus.CANCELLED_BY_MANAGER).length,
        backgroundColor: 'var(--v-quaternary-base)'
      },
      {
        val: StatusFilter.Saved,
        text: i18n.t('requests-filter-list.filter.status.saved') as string,
        count: props.teamRequests.filter((teamRequest: any) => teamRequest.status === RequestStatus.SAVED).length,
        backgroundColor: 'var(--v-secondary-base)'
      }
    ]);
    const subFilterCardComponents = computed(() => [
      {
        val: StatusFilter.Pending,
        text: i18n.t('requests.detail.status.pending') as string,
        count: props.teamRequests.filter((teamRequest: any) => teamRequest.status === RequestStatus.PENDING).length
      },
      {
        val: StatusFilter.Validated,
        text: i18n.t('requests.detail.status.validated') as string,
        count: props.teamRequests.filter((teamRequest: any) => teamRequest.status === RequestStatus.VALIDATED).length
      }
    ]);

    onMounted(() => {
      const storedFilters = store.getters['pagesStore/client/teamRequests/filters'];
      const statusIds = storedFilters.status;
      if (statusIds) {
        selectedFilterCard.value = mapStatusListToStatusFilter(statusIds);
        selectFilterCard(selectedFilterCard.value);
      }
    });

    const mapStatusListToStatusFilter = (status: RequestStatus[]) => {
      if (status.includes(RequestStatus.SAVED)) {
        return StatusFilter.Saved;
      } else if (status.includes(RequestStatus.ON_GOING)) {
        return StatusFilter.Approved;
      } else if (status.includes(RequestStatus.PENDING) && status.includes(RequestStatus.VALIDATED)) {
        return StatusFilter.OnGoing;
      } else if (status.includes(RequestStatus.PENDING)) {
        return StatusFilter.Pending;
      } else if (status.includes(RequestStatus.VALIDATED)) {
        return StatusFilter.Validated;
      } else if (status.includes(RequestStatus.CANCELLED_BY_MANAGER)) {
        return StatusFilter.Rejected;
      } else {
        return StatusFilter.All;
      }
    };

    const selectFilterCard = status => {
      selectedFilterCard.value = status;
      props.onClick(status);
    };

    const selectSubFilterCard = status => {
      if (selectedSubFilterCard.value.includes(status)) {
        selectedSubFilterCard.value = selectedSubFilterCard.value.filter(val => val !== status);
      } else {
        selectedSubFilterCard.value.push(status);
      }
      if (selectedSubFilterCard.value.includes(StatusFilter.Pending) && selectedSubFilterCard.value.includes(StatusFilter.Validated)) {
        selectFilterCard(StatusFilter.OnGoing);
      } else if (selectedSubFilterCard.value.includes(StatusFilter.Pending)) {
        selectFilterCard(StatusFilter.Pending);
      } else if (selectedSubFilterCard.value.includes(StatusFilter.Validated)) {
        selectFilterCard(StatusFilter.Validated);
      } else {
        selectFilterCard(StatusFilter.All);
      }
    };

    const isFilterActive = statusFilter => {
      if (
        selectedFilterCard.value === StatusFilter.Saved ||
        selectedFilterCard.value === StatusFilter.Approved ||
        selectedFilterCard.value === StatusFilter.OnGoing ||
        selectedFilterCard.value === StatusFilter.Rejected ||
        selectedFilterCard.value === StatusFilter.All
      ) {
        return statusFilter === selectedFilterCard.value;
      } else if (selectedFilterCard.value === StatusFilter.Pending || selectedFilterCard.value === StatusFilter.Validated) {
        return statusFilter === StatusFilter.OnGoing;
      }
    };

    const isSubFilterActive = statusFilter => {
      return selectedSubFilterCard.value.includes(statusFilter);
    };

    return {
      filterCardComponents,
      subFilterCardComponents,
      selectedFilterCard,
      selectedSubFilterCard,
      StatusFilter,
      RequestStatus,
      selectSubFilterCard,
      selectFilterCard,
      isFilterActive,
      isSubFilterActive
    };
  }
});
